import { signOut } from "firebase/auth";
import { auth } from "firebase-config";
import * as Sentry from "@sentry/react";
import { AppDispatch } from "main-store";

export const logout = async (dispatch: AppDispatch) => {
  await signOut(auth);
  Sentry.setUser(null);
  dispatch({ type: "LOGOUT" });
  // clean localstorage?
};
