import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import DrawerToggle from "../Drawer/DrawerHeader/DrawerToggle";
import AppBar, { AppBarProps } from "@mui/material/AppBar";

interface HeaderProps {
  open: boolean;
  handleDrawerToggle: () => void;
}

export default function Header({ open, handleDrawerToggle }: HeaderProps) {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));

  // app-bar params
  const appBar: AppBarProps = {
    position: "fixed",
    color: "inherit",
    elevation: 0,
    sx: {
      borderBottom: "1px solid",
      borderBottomColor: "divider",
      zIndex: 1200,
      width: {
        xs: "100%",
      },
    },
  };

  return (
    <>
      {matchDownLG ? (
        <AppBar {...appBar}>
          <Toolbar>
            <DrawerToggle open={open} handleDrawerToggle={handleDrawerToggle} background />
          </Toolbar>
        </AppBar>
      ) : (
        <></>
      )}
    </>
  );
}
