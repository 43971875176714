// types
import { DefaultConfigProps } from "types/config";

// ==============================|| THEME CONSTANT ||============================== //

export const APP_DEFAULT_PATH = "/dashboard/default";
export const HORIZONTAL_MAX_ITEM = 7;
export const DRAWER_WIDTH = 280;
export const MINI_DRAWER_WIDTH = 80;

export enum SimpleLayoutType {
  SIMPLE = "simple",
  LANDING = "landing",
}

export enum ThemeMode {
  LIGHT = "light",
  DARK = "dark",
}

export enum MenuOrientation {
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
}

export enum ThemeDirection {
  LTR = "ltr",
  RTL = "rtl",
}

export enum NavActionType {
  FUNCTION = "function",
  LINK = "link",
}

export enum Gender {
  MALE = "Male",
  FEMALE = "Female",
}

export enum DropzopType {
  DEFAULT = "default",
  STANDARD = "standard",
}

// ==============================|| THEME CONFIG ||============================== //

const config: DefaultConfigProps = {
  fontFamily: `'Public Sans', sans-serif`,
  i18n: "en",
  menuOrientation: MenuOrientation.VERTICAL,
  miniDrawer: false,
  container: true,
  mode: ThemeMode.LIGHT,
  presetColor: "default",
  themeDirection: ThemeDirection.LTR,
};

export default config;
