import { Theme } from "@mui/material/styles";

// ==============================|| OVERRIDES - ALERT TITLE ||============================== //

export default function Accordion(theme: Theme) {
  return {
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
        square: true,
        elevation: 0,
      },
      styleOverrides: {
        root: {
          "&:not(:last-child)": {
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          },
          "&:before": {
            display: "none",
          },
          "&.Mui-expanded": {
            "&:not(:last-child)": {
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            },
          },
        },
      },
    },
  };
}
