import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { styled, Theme } from "@mui/material/styles";

interface ExpandMoreProps extends IconButtonProps {
  theme: Theme;
  expand: boolean;
}

export const ExpandMore = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "theme" && prop !== "expand",
})(({ theme, expand }: ExpandMoreProps) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(-90deg)",
  marginLeft: "auto",
  color: theme.palette.secondary.dark,
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
