import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

// material-ui
import { useMediaQuery, useTheme } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { openDrawer } from "main-store/reducers/menu";

// assets
import { LogoutOutlined, SettingOutlined } from "@ant-design/icons";

interface ProfileMenuProps {
  handleLogout: () => void;
  closeMenu: () => void;
}

export default function ProfileMenu({ handleLogout, closeMenu }: ProfileMenuProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));
  const dispatch = useDispatch();

  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, route: string = "") => {
    if (route && route !== "") {
      navigate(route);
      if (matchDownLG) {
        dispatch(openDrawer({ drawerOpen: false }));
      }
    }
    closeMenu();
  };

  return (
    <List
      component="nav"
      sx={{
        py: "8px",
        "& .MuiListItemButton-root": {
          height: "48px",
        },
      }}
    >
      <ListItemButton
        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
          handleListItemClick(event, "/account/settings/personal")
        }
      >
        <ListItemIcon>
          <SettingOutlined style={{ fontSize: "14px", color: "#8C8C8C" }} />
        </ListItemIcon>
        <ListItemText primary="Account Settings" />
      </ListItemButton>
      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined style={{ fontSize: "14px", color: "#8C8C8C" }} />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
}
