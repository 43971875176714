import { forwardRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project import
import { activeItem, openDrawer } from "main-store/reducers/menu";

// types
import { NavItemType } from "types/menu";

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

interface NavItemProps {
  item: NavItemType;
  level: number;
  isParents?: boolean;
}

const NavItem = ({ item, level }: NavItemProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));

  // @ts-ignore
  const { drawerOpen, openItem } = useSelector((state) => state.menu);

  let itemTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      // @ts-ignore
      <Link ref={ref} {...props} to={item.url} target={itemTarget} />
    )),
  };
  if (item?.external) {
    // @ts-ignore
    listItemProps = { component: "a", href: item.url, target: itemTarget };
  }

  const itemHandler = (id: string | undefined) => {
    if (id) {
      dispatch(activeItem({ openItem: [id] }));
    }
    if (matchDownLG) {
      dispatch(openDrawer({ drawerOpen: false }));
    }
  };

  const isSelected = openItem.findIndex((id: string) => id === item.id) > -1;

  const textColor = drawerOpen ? "#424242" : "#8C8C8C";
  const selectedTextColor = "#202020";

  const Icon = item.icon;
  const itemIcon = Icon ? (
    <Icon
      style={{
        color: isSelected ? selectedTextColor : textColor,
        fontSize: drawerOpen ? "16px" : "20px",
      }}
    />
  ) : (
    false
  );

  // active menu item on page load
  useEffect(() => {
    const isPathInExtraUrls = item.extraUrls?.some((extraUrl: string) =>
      pathname.includes(extraUrl)
    );
    if ((item.url && pathname.includes(item.url)) || isPathInExtraUrls) {
      dispatch(activeItem({ openItem: [item.id] }));
    } else if (pathname != "/" && isSelected) {
      dispatch(activeItem({ openItem: [] }));
    }
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      onClick={() => itemHandler(item.id)}
      selected={isSelected}
      sx={{
        zIndex: 1201,
        pl: drawerOpen ? `${level * 24}px` : undefined,
        py: drawerOpen ? "12px" : "20px",
        mb: drawerOpen ? "6px" : "8px",
        justifyContent: "center",
        "&:hover": {
          borderRadius: "8px",
        },
        "&.Mui-selected": {
          bgcolor: theme.palette.background.paper,
          color: selectedTextColor,
          borderRadius: "8px",
          boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 6px -1px rgba(0, 0, 0, 0.16)",
          "&:hover": {
            bgcolor: theme.palette.background.paper,
          },
        },
      }}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            color: isSelected ? selectedTextColor : textColor,
            ...(!drawerOpen && {
              alignItems: "center",
              justifyContent: "center",
            }),
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && (
        <ListItemText
          primary={
            <Typography
              variant="h6"
              sx={{
                color: isSelected ? selectedTextColor : textColor,
                fontWeight: isSelected ? 600 : 500,
              }}
            >
              {item.title}
            </Typography>
          }
        />
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

export default NavItem;
