import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { To } from "history";

// material-ui
import { ButtonBase, useMediaQuery } from "@mui/material";
import { SxProps, useTheme } from "@mui/material/styles";

// project import
import Logo from "./Logo";
import { APP_DEFAULT_PATH } from "config";
import { activeItem, openDrawer } from "main-store/reducers/menu";

// ==============================|| MAIN LOGO ||============================== //

interface LogoSectionProps {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
  fullLogo: boolean;
  height: number;
}

const LogoSection = ({ sx, to, fullLogo, height }: LogoSectionProps) => {
  // @ts-ignore
  const { defaultId } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));

  const onLogoClick = () => {
    dispatch(activeItem({ openItem: [defaultId] }));
    if (matchDownLG) {
      dispatch(openDrawer({ drawerOpen: false }));
    }
  };

  return (
    <ButtonBase
      disableRipple
      component={Link}
      onClick={onLogoClick}
      // @ts-ignore
      to={!to ? APP_DEFAULT_PATH : to}
      sx={sx}
    >
      <Logo fullLogo={fullLogo} height={height} />
    </ButtonBase>
  );
};

export default LogoSection;
