import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

// project import
import Navigation from "./Navigation";
import SupportCard from "components/SupportCard";
import SimpleBar from "components/third-party/SimpleBar";
import Profile from "layouts/MainLayout/Drawer/DrawerContent/Profile";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
  const { drawerOpen } = useSelector((state: any) => state.menu);
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <>
      <SimpleBar
        sx={{
          pl: drawerOpen ? "12px" : "8px",
          pr: matchDownLG ? "12px" : "2px",
          "& .simplebar-content": {
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        {drawerOpen && (
          <Typography sx={{ color: "grey.500", mt: "32px", p: "12px 25px" }}>
            Your Workspace
          </Typography>
        )}
        <Navigation />
        <Divider sx={{ px: "6px", borderColor: "#D8D8D8" }} />
      </SimpleBar>
      <Box sx={{ p: drawerOpen ? "24px 12px" : "24px 12px 24px 8px", mt: "auto" }}>
        <SupportCard drawerOpen={drawerOpen} />
      </Box>
      <Profile drawerOpen={drawerOpen} />
    </>
  );
};

export default DrawerContent;
