// material-ui
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";

// project import
import { DRAWER_WIDTH, MINI_DRAWER_WIDTH } from "config";

const openedMixin = (theme: Theme) =>
  ({
    width: DRAWER_WIDTH,
    borderRight: "none",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    background: "transparent",
  }) as CSSObject;

const closedMixin = (theme: Theme) =>
  ({
    width: MINI_DRAWER_WIDTH,
    borderRight: "none",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    background: "transparent",
  }) as CSSObject;

// ==============================|| DRAWER - MINI STYLED ||============================== //

const MiniDrawerStyled = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default MiniDrawerStyled;
