// material-ui
import { Theme } from "@mui/material/styles";

// assets
import RightOutlined from "@ant-design/icons/RightOutlined";

// ==============================|| OVERRIDES - ALERT TITLE ||============================== //

export default function AccordionSummary(theme: Theme) {
  return {
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <RightOutlined style={{ fontSize: "0.75rem" }} />,
      },
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[50],
          flexDirection: "row-reverse",
          minHeight: 46,
          paddingX: "16px",
        },
        expandIconWrapper: {
          color: theme.palette.secondary[800],
          "&.Mui-expanded": {
            transform: "rotate(90deg)",
          },
        },
        content: {
          marginLeft: "12px",
        },
      },
    },
  };
}
