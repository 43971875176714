import { MailOutlined } from "@ant-design/icons";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface SupportCardProps {
  drawerOpen: boolean;
}

export default function SupportCard({ drawerOpen }: SupportCardProps) {
  const handleClick = () => {
    const email = "hey@aigency.ai";
    const subject = encodeURIComponent("Aigency Portal Help & Support");
    window.location.href = `mailto:${email}?subject=${subject}`;
  };

  return (
    <Box
      sx={{
        borderRadius: drawerOpen ? "12px" : "8px",
        padding: "2px",
        background:
          "linear-gradient(150deg, #5BC4FF 6.97%, #DBDFF4 50.84%, #DBDFF4 52.6%, #9042DB 93.65%)",
      }}
    >
      <Box
        sx={{
          borderRadius: drawerOpen ? "10px" : "6px",
          background: "linear-gradient(145deg, #D8E3F5 30.36%, #DDD8F2 100%)",
        }}
      >
        {drawerOpen && (
          <Stack
            display="flex"
            direction="column"
            width="100%"
            padding="20px 20px 30px 20px"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h5" fontWeight={700}>
              Help & Support
            </Typography>
            <Typography variant="body2" color="secondary">
              Have any questions?
            </Typography>
            <Button
              onClick={handleClick}
              variant="contained"
              sx={{
                mt: "14px",
                padding: "9px 21px 9px 24px",
                borderRadius: "1000px",
                border: "1px solid #262626",
                backgroundColor: "#262626",
                boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.04)",
                "&:hover": {
                  backgroundColor: "#262626",
                },
              }}
              startIcon={<MailOutlined />}
            >
              Email us
            </Button>
          </Stack>
        )}
        {!drawerOpen && (
          <IconButton
            onClick={handleClick}
            sx={{
              width: "100%",
              height: "100%",
              py: "20px",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <MailOutlined style={{ fontSize: "20px", color: "#9042DB" }} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}
