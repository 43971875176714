// material-ui
import { Box, Typography } from "@mui/material";

// project import
import useAuth from "hooks/useAuth";
import menuItems from "menu-items";
import NavGroup from "./NavGroup";

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const { isAdmin } = useAuth();

  const filteredMenuItems = menuItems.items.filter((item) => {
    if (item.adminOnly) {
      return isAdmin;
    }
    return true;
  });

  const navGroups = filteredMenuItems.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box>{navGroups}</Box>;
};

export default Navigation;
