import IconButton from "@mui/material/IconButton";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

interface DrawerToggleProps {
  open: boolean;
  handleDrawerToggle: () => void;
  background?: boolean;
}

export default function DrawerToggle({ open, handleDrawerToggle, background }: DrawerToggleProps) {
  return (
    <IconButton
      aria-label="open drawer"
      onClick={handleDrawerToggle}
      sx={{
        color: "grey.600",
        backgroundColor: background ? "grey.200" : "transparent",
      }}
    >
      {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
    </IconButton>
  );
}
