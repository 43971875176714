import * as Sentry from "@sentry/react";

export function logInfo(message: string, extra: any = {}) {
  console.log(message);
  Sentry.captureMessage(message, {
    level: "info",
    extra,
  });
}

export function logError(message: string, errorMessage: string) {
  console.error(message);
  Sentry.captureException(new Error(message), { extra: { errorMessage } });
}
