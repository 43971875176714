import logoLG from "assets/images/logo-lg.svg";
import logoSM from "assets/images/logo-sm.svg";

// ==============================|| LOGO ||============================== //

interface LogoProps {
  fullLogo: boolean;
  height: number;
}

const Logo = ({ fullLogo, height }: LogoProps) => {
  return <img src={fullLogo ? logoLG : logoSM} alt="Aigency" height={height} />;
};

export default Logo;
