import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  ClickAwayListener,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from "@mui/material";

// project import
import MainCard from "components/MainCard";
import Transitions from "components/@extended/Transitions";
import useUserData from "hooks/useUserData";
import { logError } from "utils/logging";
import ProfileMenu from "./ProfileMenu";
import { ExpandMore } from "./ExpandMore";
import RightOutlined from "@ant-design/icons/RightOutlined";
import { logout } from "./utils/logout";

interface ProfileProps {
  drawerOpen: boolean;
}

const Profile = ({ drawerOpen }: ProfileProps) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const anchorRef = useRef<any>(null);
  const { userProfile } = useUserData();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      await logout(dispatch);
      navigate("/auth/login");
    } catch (error: any) {
      logError("Sign out error", error.message);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Box
      sx={{
        borderTop: "1px solid",
        borderTopColor: "#D8D8D8",
      }}
    >
      <Box
        sx={{
          width: "100%",
          p: "24px 15px",
          cursor: "pointer",
        }}
        onClick={handleToggle}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
      >
        {drawerOpen && (
          <List disablePadding>
            <ListItem
              disablePadding
              secondaryAction={
                <ExpandMore
                  theme={theme}
                  expand={open}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  aria-label="show more"
                >
                  <RightOutlined style={{ fontSize: "12px", color: "#BFBFBF" }} />
                </ExpandMore>
              }
              sx={{ "& .MuiListItemSecondaryAction-root": { right: -8 } }}
            >
              <ListItemAvatar>
                <Avatar alt="Avatar" src={userProfile?.avatar} sx={{ width: 45, height: 45 }} />
              </ListItemAvatar>
              <ListItemText
                sx={{
                  width: "163px",
                  mt: 0,
                  mb: 0,
                }}
                primary={
                  <Typography
                    variant="h5"
                    fontWeight={700}
                    sx={{
                      color: "#262626",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {userProfile?.companyName}
                  </Typography>
                }
                secondary={
                  <Typography variant="h6" color="secondary">
                    Administrator
                  </Typography>
                }
              />
            </ListItem>
          </List>
        )}
        {!drawerOpen && (
          <Avatar alt="Avatar" src={userProfile?.avatar} sx={{ width: 45, height: 45 }} />
        )}
      </Box>
      <Popper
        placement={drawerOpen ? "top-end" : "top-start"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "preventOverflow",
              enabled: false,
            },
            {
              name: "offset",
              options: {
                offset: drawerOpen ? [-8, -14] : [16, -14],
              },
            },
          ],
        }}
        sx={{ position: "fixed !important" }}
      >
        {({ TransitionProps }) => (
          // @ts-ignore
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 170,
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  {/* @ts-ignore */}
                  <MainCard elevation={0} border={false} content={false}>
                    <ProfileMenu handleLogout={handleLogout} closeMenu={() => setOpen(false)} />
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
