// material-ui
import { Theme } from "@mui/material/styles";

// ==============================|| OVERRIDES - ALERT TITLE ||============================== //

export default function AccordionDetails(theme: Theme) {
  return {
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          background: theme.palette.grey[50],
          padding: "4px 16px 16px 40px",
        },
      },
    },
  };
}
