import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import Logo from "components/Logo";
import DrawerToggle from "./DrawerToggle";

// ==============================|| DRAWER HEADER ||============================== //

interface DrawerHeaderProps {
  open: boolean;
  handleDrawerToggle: () => void;
}

const DrawerHeader = ({ open, handleDrawerToggle }: DrawerHeaderProps) => {
  return (
    <Box>
      <Stack
        direction="column"
        spacing={open ? "4px" : "36px"}
        alignItems="center"
        width="100%"
        paddingBottom={open ? 0 : "24px"}
      >
        <Box
          display="flex"
          justifyContent={open ? "flex-end" : "center"}
          width="100%"
          paddingTop="12px"
          paddingX="4px"
        >
          <DrawerToggle open={open} handleDrawerToggle={handleDrawerToggle} />
        </Box>
        <Logo fullLogo={open} height={open ? 130 : 50} />
      </Stack>
    </Box>
  );
};

export default DrawerHeader;
