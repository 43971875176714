import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Toolbar, useMediaQuery } from "@mui/material";

// project import
import Drawer from "./Drawer";
import Header from "./Header";

// types
import { openDrawer } from "main-store/reducers/menu";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));
  const dispatch = useDispatch();

  // @ts-ignore
  const { drawerOpen } = useSelector((state) => state.menu);

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        background: matchDownLG ? "#F9F9F9" : theme.palette.background.default,
      }}
    >
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />

      {matchDownLG && (
        <Box
          component="main"
          sx={{ width: "100%", minHeight: "100vh", flexGrow: 1, p: "24px 20px" }}
        >
          <Toolbar sx={{ mb: "8px" }} />
          <Outlet />
        </Box>
      )}

      {!matchDownLG && (
        <Box
          component="main"
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            overflowY: "auto",
            p: "12px",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              background: "#F9F9F9",
              borderRadius: "8px",
              p: "12px 18px",
              boxShadow:
                "0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)",
            }}
          >
            <Outlet />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MainLayout;
