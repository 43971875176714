import { FileTextOutlined } from "@ant-design/icons";

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const support = {
  id: "support",
  type: "group",
  children: [
    {
      id: "documentation",
      title: "Dev & Documentation",
      type: "item",
      url: "/documentation",
      icon: FileTextOutlined,
    },
  ],
};

export default support;
